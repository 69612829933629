import Vue from 'vue'
import VueRouter from 'vue-router'
import userService from '../services/user.service'
Vue.use(VueRouter)

const routes = [

   {
     path: '/',
     name: 'Home',
     component: () => import('../views/Home'),
     meta: {
       log: false,
       admin: false,
       colaborador: false,
      auth: false,
     }
   },
 /* {
    path: '/',
    name: 'proximamente',
    component: () => import('../views/Proximamente'),
    meta: {
      log: false,
      admin: false,
      colaborador: false,
      auth: false,
    }
  },*/

  {
    path: '/animacion',
    name: 'animacion',
    component: () => import('../components/bannerAnimado'),
    meta: {
      log: false,
      admin: false,
      colaborador: false,
      auth: false,
    }
  },
  {
    path: '/producto',
    name: 'producto',
    component: () => import('../views/Home'),
    meta: {
      log: false,
      admin: false,
      colaborador: false,
      auth: false,
    }
  },
  {
    path: '/producto/:url',
    name: 'Producto',
    component: () => import('../views/Producto'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },

  {
    path: '/merchandise',
    name: 'Merchandise',
    component: () => import('../views/Proximamente'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },
  // {
  //   path: '/carrito',
  //   name: 'Carrito',
  //   component: () => import('../views/carrito/miCarrito'),
  //   meta: {
  //     log: false,
  //     admin: false,
  //     auth: false
  //   }
  // },

  {
    path: '/openpay/test',
    name: 'testing',
    component: () => import('../components/test/checkoutTest'),
    meta: {
      log: false,
      admin: false,
      auth: false
    }
  },

  {
    path: '/check-out',
    name: 'check-out',
    component: () => import('../views/carrito/checkOut'),
   
    meta: {
      log: true,
      admin: false,
      auth: false
    }
  },

  {
    path: '/finalizado',
    name: 'finished',
    component: () => import('../views/carrito/Finished'),
    beforeEnter: async (to, from, next) => {

      if (from.path != '/check-out') { //solo entrar a esta vista desde mi carrito.
        next('/')
      } else {
        next();
      }
      

    },
    meta: {
      log: true,
      admin: false,
      auth: false
    }
  },

  {
    path: '/finalizadopr',
    name: 'finishedpr',
    component: () => import('../views/carrito/Finishedpr'),
    meta: {
      log: true,
      admin: false,
      auth: false
    }
  },

  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginReg'),
    meta: {
      log: false,
      admin: false,
      colaborador: false,
      auth: false,
    }
  },

  {
    path: '/auth',
    name: 'autentificacion',
    component: () => import('../views/Autentificacion'),
    meta: {
      log: true,
      admin: true,
      colaborador: false,
      auth: false
    }
  },

  {
    path: '/administrador',
    name: 'Dashboard',
    component: () => import('../views/Dashboard'),
    meta: {
      log: true,
      admin: true,
      colaborador: false,
      auth: true,
    }
  },

  {
    path: '/addtocar',
    name: 'CartAdd',
    component: () => import('../views/Producto'),
    meta: {
      log: false,
      admin: false,
      auth: false,
    }
  },
  {
    path: '/cesta',
    name: 'CartModal',
    component: () => import('../views/Producto'),
    meta: {
      log: false,
      admin: false,
      auth: false,
    }
  },

  {
    path: "/mi-cuenta/:name",
    name: "MiCuenta",
    component: () => import("../views/micuenta/MiCuenta"),
    meta: {
      log: true,
      admin: false,
      auth: false,
    },
  },

  {
    path: "/mi-cuenta/direccion/:name",
    name: "MiCuentaDireccion",
    component: () => import("../views/micuenta/MiCuenta"),
    meta: {
      log: true,
      admin: false,
      auth: false,
    },
  },
  {
    path: "/aviso-de-privacidad",
    name: "Aviso de privacidad",
    component: () => import("../views/AvisoPrivacidad"),
    meta: {
      log: false,
      admin: false,
      auth: false,
    }
  },
  {
    path: "/terminos-y-condiciones",
    name: "Términos y servicio",
    component: () => import("../views/TerminosServicio"),
    meta: {
      log: false,
      admin: false,
      auth: false,
    }
  },
  {
    path: '*',
    name: 'Error',
    component: () => import('../views/Error404'),
    meta: {
      log: false,
      admin: false,
      colaborador: false,
      auth: false
    }
  },


]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {

  let meta_admin = to.matched.some(record => record.meta.admin);
  let meta_colaborador = to.matched.some(record => record.meta.colaborador);
  let meta_log = to.matched.some(record => record.meta.log);
  let meta_auth = to.matched.some(record => record.meta.auth);

  let tokenexp = await userService.tokenexp()
  let check = false //comprobar si esta logeado
  let admin = false;
  let colaborador = false;
  let type = ''


  /*if (to.name !== 'Producto' || to.name !== 'CartAdd' || to.name !== 'CartModal') {
    localStorage.removeItem('pdt')
    localStorage.removeItem('mdc')
  }*/

  if (tokenexp) { // ver que el token no este expirado.
    localStorage.removeItem('tkn')
    localStorage.removeItem('ath') //limpiar el localstorage
    next()
  }


  let response = await userService.getData()

  if (response.status == 'error') {
    localStorage.removeItem('tkn')
    localStorage.removeItem('ath')
  }


  if (response.status == 'success') { //comprobar que sea un login valido.
    check = true;
    let isAdmin = userService.isAdmin(response.result);
    admin = isAdmin.admin
    type = isAdmin.type

    let isTch = userService.isTch(response.result);
    colaborador = isTch.tch

  }

  let ath = await userService.getAth();


  // borrar el localstorage cada que vayamos a login.

  if (to.path === '/login' && check) {
    localStorage.removeItem('tkn')
    localStorage.removeItem('ath')

    next()
  }

  //redireccionamientos por rol.

  if (!meta_log) next();

  if (meta_log && !check) next('/login');

  if (meta_admin) {
    if (admin) {

      if (meta_auth) {
        if (ath) {
          next();
        } else {
          localStorage.removeItem('tkn')
          localStorage.removeItem('ath')
          next('/login')
        }
      } else { //...proximamente rutas que no requieran autentificacion
        next()
      }

    } else {
      localStorage.removeItem('tkn')
      localStorage.removeItem('ath')
      next('/')
    }
  } else if (meta_colaborador) {
    if (colaborador) {

      if (meta_auth) {
        if (ath) {
          next();
        } else {
          localStorage.removeItem('tkn')
          localStorage.removeItem('ath')
          next('/login')
        }
      } else { //...proximamente rutas que no requieran autentificacion
        next()
      }

    } else {
      localStorage.removeItem('tkn')
      localStorage.removeItem('ath')
      next('/')
    }

  } else {
    next()
  }


});




export default router

