<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "App",
  data() {
    return {
      colores: "",
    };
  },

  async created() {
    await this.getAllInfoClr("profile");



    this.colores = this.profiles;
    this.actualizarColores();

    let _ctk = localStorage.getItem("_ctk");

    if (!_ctk) {
      let payload = {
        option: "set_cart",
        item: { id_cliente: null },
      };
      this.setCart(payload);
    }

    if (this.$route.name !== 'Producto' || this.$route.name !== 'CartAdd' || this.$route.name !== 'CartModal') {
      localStorage.removeItem('pdt')
      localStorage.removeItem('mdc')
    }
  },
  watch: {
    profiles() {
      this.actualizarColores();
    },
  },
  computed: {
    profiles() {
      return this.$store.getters["profile/data"];
    },
  },
  methods: {
    ...mapActions("colors", ["getAllInfoClr"]),
    ...mapActions("profile", ["getAllInfoPrf"]),
    ...mapActions("carrito", ["setCart"]),
    actualizarColores() {
      const arr = this.$store.getters["colors/data"];

      if (Array.isArray(arr)) {
        for (var i = 0; i < arr.length; i++) {
          if (i == 0) {
            document.documentElement.style.setProperty(
              "--color-1",
              arr[i].code
            );
          } else if (i == 1) {
            document.documentElement.style.setProperty(
              "--color-2",
              arr[i].code
            );
          } else if (i == 2) {
            document.documentElement.style.setProperty(
              "--color-3",
              arr[i].code
            );
          } else if (i == 3) {
            document.documentElement.style.setProperty(
              "--color-4",
              arr[i].code
            );
          } else if (i == 4) {
            document.documentElement.style.setProperty(
              "--color-5",
              arr[i].code
            );
          } else if (i == 5) {
            document.documentElement.style.setProperty(
              "--color-6",
              arr[i].code
            );
          } else if (i == 6) {
            document.documentElement.style.setProperty(
              "--color-7",
              arr[i].code
            );
          }
        }
      }
    },
  },
};
</script>

<style>
@import "./css/default.css";

@import url("https://fonts.googleapis.com/css2?family=Mukta:wght@300;400;500;600;700;800&family=Open+Sans:wght@300;400;500;600;700;800&family=Sora:wght@100;200;300;400;500;600;700;800&family=Source+Sans+Pro:wght@300;400;600;700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800;900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@300;400;500;600;700;800;900&display=swap");

@font-face {
  font-family: "Piknic-Regular"
    /*400*/
  ;
  src: url("./assets/fonts/Piknic-Regular.otf");
  font-display: swap;
}

@font-face {
  font-family: "Kinetica-Bold";
  /*700 */
  src: url("./assets/fonts/Kinetica-Bold.otf");
  font-display: swap;
}

@font-face {
  font-family: "Catou";
  src: url("./assets/fonts/Catou.otf");
  font-display: swap;
}

html {
  scroll-behavior: smooth;
}

.cupon {
  font-family: Kinetica-Bold !important;
  text-align: center !important;
}


.cupon__succes>p {
  color: #194C06 !important;
}

.cupon__error>p {
  color: #FF3723 !important;
}

:root {
  /** No eliminar los 7 colores */
  --color-1: #e21f1d;
  --color-2: #242424;
  --color-3: #a7a7a7;
  --color-4: #f5f5f5;
  --color-5: #ffffff;
  --color-6: #d9d9d9;
  --color-7: #ffffff;
  --color-8: #59c1af;
  --color-9: #787878;
  --color-10: #2b4a88;
  --color-11: #99ded2;
  --color-12: #4678ba;

  --Sans: "Source Sans Pro", sans-serif;
  --Open_Sans: "Open Sans", sans-serif;
  --Sora: "Sora", sans-serif;
  --Mukta: "Mukta", sans-serif;
  --dmsans: "DM Sans", sans-serif;
  --montserrat: "Montserrat", sans-serif;
  --inter: "Inter", sans-serif;

  --redHatDisplay: "Red Hat Display", sans-serif;

  --fs-idseccionpc: 0.625vw;

  /* Tipografias */
  --font-titulo1: "Outfit";
  --font-titulo2: "Outfit";
  --font-parrafo: "Outfit";
  --font-parrafo2: "Outfit";


  /**Modal carrito */
  --co-tlMCar: #194C06;
  --font-tlMCar: "Kinetica-Bold";
  --co-cantMCar: white;
  --bk-cantMCar: #FF4713;
  --font-ptlMCar: "Kinetica-Bold";
  --co-ptlMCar: #FF4713;
  --co-stlMCar: #BABABA;
  --co-btncMCar: #FF4713;
  --co-txtcMCar: #194C06;
  --co-priceMCar: white;
  --bk-priceMCar: #FF4713;
  --font-sbtMCar: "Kinetica-Bold";
  --co-sbtMCar: #194C06;
  --co-sbtpMCar: #FF4713;
  --b-fcMCar: #FF4713;
  --bk-fcMCarYll: #ffa400;
  --co-fcMCar: #FF4713;

  /**MI CARRITO */
  --co-btnMyCar: #FF3723;
  --co-htlMyCar: #194C06;
  --co-tlMyCar: white;
  --font-MyCar: "Kinetica-Bold";

  /**CHECKOUT */
  --co-btnMyCar: #FF3723;
  --co-htlMyCar: #194C06;
  --co-COutTYC: #FF3723;

  /**FINISHED  */
  --co-btnMyCar: #FF3723;
  --co-fintlMyCar: #194C06;

  /**OPENPAY  */
  --bk-inputOPay: transparent;
  --co-txtOPay: #FF3723;
}

a {
  text-decoration: none;
  color: inherit;
}

.algc {
  align-items: center;
}

.jcr {
  justify-content: flex-end;
}

.jcsb {
  justify-content: space-between;
}

.jcse {
  justify-content: space-evenly;
}

.tac {
  text-align: center;
}

.pointer {
  cursor: pointer;
}

.aibs {
  align-items: baseline;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0vw;
  overflow: hidden;
}

/*INICIO SLIDE CAROUSEL TESTIMONIOS*/

.slideA .VueCarousel-dot-container {
  display: flex !important;
  justify-content: center;
  width: 47vw;
  margin: 0 auto;
  padding: 0;
}

.slideA .VueCarousel-dot {
  width: 6.763285024154589vw !important;
  height: 6.763285024154589vw !important;
}

.slideA button.VueCarousel-dot.VueCarousel-dot--active {
  /*width: 100% !important;*/
  background-color: #4678ba !important;
  outline: 0px !important;
  border-radius: 10vw !important;
}

.slideA .VueCarousel-dot:focus {
  /*background-color: #4678BA !important;*/
  outline: 0px solid lightblue !important;
}

/* estilos del administrador */
.opt-c {
  font-size: 0.9722222222222222VW;
  padding: 0.51vw;
  margin-right: 0.42vw;
  background-color: gray;
  color: white;
  border-radius: 2vw;
  cursor: pointer;
}

.opt-c.active {
  background-color: var(--color-1);
}

.ProRel .VueCarousel-navigation-button {
  color: transparent !important;
  padding: 0 !important;
  font-size: 5.128vw !important;
}

.VueCarousel-navigation-button {
  background-image: url("assets/flecha_escritorio.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.cedula {
  width: 41.9vw;
  margin-top: 1vw;
}

.cedula_frame iframe {
  width: 41.9vw;
  height: 25vw;
  margin-top: 1vw;
  margin-bottom: 2vw;
}

.cedula_img {
  height: 25vw;
  width: 43vw;
  overflow: auto;
  margin-bottom: 2vw;
}

.cedula_img img {
  width: 41vw;
  margin-top: 1vw;


}

a.cedula_visualizar {
  text-transform: uppercase;
  background-color: #242424;
  color: white;
  text-decoration: none;
  padding: .5vw 1vw;
  font-size: 1vw;
  margin-left: 35vw;
}

@media (min-width: 769px) {

  .VueCarousel-navigation-button {
    width: 2.65625VW;
    height: 1.0416666666666665VW;
    filter: invert(19%) sepia(32%) saturate(3795%) hue-rotate(74deg) brightness(95%) contrast(95%);
  }

  .VueCarousel-navigation-prev {
    transform: translateY(-50%) translateX(-100%) rotate(90deg) !important;
  }

  .VueCarousel-navigation-next {
    transform: translateY(-50%) translateX(100%) rotate(-90deg) !important;
  }

  .VueCarousel-navigation--disabled {
    filter: invert(79%) sepia(63%) saturate(447%) hue-rotate(7deg) brightness(92%) contrast(85%);
  }

  .slideA .VueCarousel-dot-container {
    width: 6.458333333333334vw;
  }

  .slideA .VueCarousel-dot {
    width: 0.8348958333333333vw !important;
    height: 0.8348958333333333vw !important;
  }
}

/*FIN SLIDE CAROUSEL TESTIMONIOS*/

/*INICIO SLIDE CAROUSEL BLOG*/
.slideB .VueCarousel-inner {
  width: 52vw;
}

.slideB .VueCarousel-slide {
  /* flex-basis: inherit; */
  /* backface-visibility: hidden; */
  width: 52vw;
}

@media (min-width: 769px) {
  .slideB .VueCarousel-inner {
    width: 44vw;
  }

  .slideB .VueCarousel-slide {
    width: 44vw;
  }
}

/*FIN SLIDE CAROUSEL BLOG*/

/*INICIO SLIDE CAROUSEL EQUIPO*/

.slideC .VueCarousel-dot-container {
  display: flex !important;
  justify-content: center;
  width: 47vw;
  margin: 0 auto;
  padding: 0;
  margin-top: 0vw !important;
}

.slideC .VueCarousel-dot {
  width: 6.763285024154589vw !important;
  height: 6.763285024154589vw !important;
  margin-top: 0vw !important;
}

.slideC button.VueCarousel-dot.VueCarousel-dot--active {
  background-color: #4678ba !important;
  outline: 0px !important;
  border-radius: 10vw !important;
}

.slideC .VueCarousel-dot:focus {
  /*background-color: #4678BA !important;*/
  outline: 0px solid lightblue !important;
}

.slideC .VueCarousel-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
  padding-bottom: 8vw;
}

@media (min-width: 769px) {
  .slideC .VueCarousel-dot-container {
    width: 6.458333333333334vw;
  }

  .slideC .VueCarousel-dot {
    width: 0.8348958333333333vw !important;
    height: 0.8348958333333333vw !important;
  }
}

/*FIN SLIDE CAROUSEL EQUIPO*/

body {
  margin: 0vw;
}

html {
  scroll-behavior: smooth;
}

input:focus-visible {
  outline: none !important;
}

input,
textarea,
select {
  padding-left: 0.5vw;
  outline: none;
}

.dflx {
  display: flex;
}

.dflxc {
  display: flex;
  justify-content: center;
}

.wfit {
  width: fit-content;
}

.pointer,
.cp {
  cursor: pointer;
}

.jcsb {
  justify-content: space-between;
}

.box_ctr {
  display: flex;
  justify-content: center;
}

.box_rh {
  display: flex;
  justify-content: right;
}

.box_ctr_alg {
  display: flex;
  justify-content: center;
  align-items: center;
}

.crs_ptr {
  cursor: pointer;
}

/* Letras rueda */
.circle .text span {
  position: absolute;
  font-family: "Kinetica-Bold";
  color: #194c06;
  left: 50%;
  width: 0;
  display: flex;
  justify-content: center;
}

/* Cel */
@media (max-width: 768px) {

  .cupon {
    font-size: 2.564vw !important;
    line-height: 188% !important;
  }

  .VueCarousel-navigation-button {
    width: 12.077294685990339VW;
    height: 4.830917874396135VW;
    filter: invert(19%) sepia(32%) saturate(3795%) hue-rotate(74deg) brightness(95%) contrast(95%);
  }

  .VueCarousel-navigation-prev {
    transform: translateY(-50%) translateX(-80%) rotate(90deg) !important;
  }

  .VueCarousel-navigation-next {
    transform: translateY(-50%) translateX(80%) rotate(-90deg) !important;
  }

  .VueCarousel-navigation--disabled {
    filter: invert(79%) sepia(63%) saturate(447%) hue-rotate(7deg) brightness(92%) contrast(85%);
    opacity: 1 !important;
  }

  .circle .text span {
    transform-origin: 0 39.208333vw;
    font-size: 6.4vw;
  }

  .msg p {
    font-size: 4vw;
  }
}

/* PC */
@media (min-width: 768px) {
  .cupon {
    font-size: 0.8VW !important;
    line-height: 188% !important;
  }

  .circle .text span {
    font-size: 1.566667VW;
    transform-origin: 0 9.708333vw;
  }

  .store .VueCarousel-slide {
    flex-basis: unset;
    flex-grow: unset;
    flex-shrink: unset;
    user-select: none;
    backface-visibility: hidden;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    outline: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1.054167VW;
    max-height: 67.695833VW;
    -ms-overflow-style: none;
    scrollbar-width: none;

  }

  .store .VueCarousel-slide::-webkit-scrollbar {
    display: none;
  }

  .store .VueCarousel-pagination {
    width: 100%;
    text-align: center;
  }

  .VueCarousel-navigation--disabled {
    opacity: 1 !important;
  }
}

/**ESTILOS ADMINISTRADOR */
.tabla_perzonalidada {
  width: 60%;
  margin-top: 1vw;
}

.header_tp {
  display: flex;
  height: 2vw;
  align-items: center;
}

.header_tp p {
  margin: 0vw;
  color: white;
  font-size: 1vw;
  width: 30%;
  height: 2vw;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid white;
  text-align: center;
  background: var(--color-1);
}

.header_tp p:first-child {
  width: 80%;
}

.header_tp p:last-child {
  width: 20%;
}

.body_tp {
  overflow: auto;
  margin-top: 0.5vw;
  margin-bottom: 2vw;
}

.content-tbl-items {
  margin: 0.4vw 0;
}

.bodytbl-items {
  border: 0.052083vw solid var(--color-2);
  padding: 0.5vw;
  height: 5vw;
  font-size: 0.8vw;
  overflow: auto;
  background: var(--color-2);
  color: var(--color-5);
}

.two-options .to-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 8.75vw;
  height: 1.75vw;
  background: rgb(165, 165, 165);
  border-radius: 2vw;
  font-size: 0.7291666666666666vw;
  color: white;
  font-weight: 600;
  margin-right: 0.2vw;
  cursor: pointer;
  transition: 0.5;
  -webkit-transition: 0.5;
  -moz-transition: 0.5;
  -ms-transition: 0.5;
  -o-transition: 0.5;
}

.two-options .to-btn:last-child {
  margin-right: 0;
}

.two-options .to-btn.active {
  background: var(--color-2);
}

.alta {
  cursor: pointer;
  border: none;
  background: var(--color-1);
  color: var(--color-5);
  width: 6vw;
  height: 2.5vw;
  border-radius: 1.5vw;
  margin-top: 0.7291666666666666vw;
  font-weight: 600;
  font-size: 0.7291666666666666vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-edit-adm {
  width: 46vw;
  height: 3vw;
  margin: auto;
  background-color: var(--color-1);
  border-radius: 1vw;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.header-edit-adm a {
  font-size: 0.7vw;
  color: white;
  font-family: var(--font-titulo2);
  background: transparent;
  border-bottom: 0.1vw solid transparent;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
}

.header-edit-adm a:hover {
  border-bottom: 0.1vw solid white;
}

.header-edit-adm.fixed {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 82.5vw;
  border-radius: 0;
  z-index: 1;
}

p.subtitle {
  color: var(--color-2);
  margin: 0vw;
  font-size: 1.3020833333333335vw;
  margin-top: 2vw;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 1vw;
}

p.hrd_tbli {
  background: var(--color-1);
  color: var(--color-5);
  text-align: center;
  padding: 0.5vw 0vw;
  margin: 0vw;
  font-size: 0.8vw;
}

.sinitems {
  background: #d6d6d6;
  width: 100%;
}

.sinitems p {
  text-align: center;
  color: #2e2e2e;
  margin: 0vw;
  height: 2vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8vw;
  text-transform: uppercase;
}

.in1.rounded {
  border-radius: 0vw 1.5vw 0vw 0vw !important;
  border-bottom: transparent !important;
}

textarea#descr,
textarea#wlink,
textarea#address {
  max-width: 37vw;
  min-width: 37vw;
  min-height: 5vw;
}

.header_tp {
  display: flex;
  height: 2vw;
  align-items: center;
}

.header_tp p {
  margin: 0vw;
  color: white;
  font-size: 1vw;
  width: 30%;
  height: 2vw;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid white;
  text-align: center;
  background: var(--color-2);
}

.header_tp p:first-child {
  width: 50%;
}

.header_tp p:last-child {
  width: 20%;
}

.fila p {
  color: rgb(51, 51, 51);
  font-size: 1vw;
  width: 30%;
  height: 2vw;
  display: flex;
  align-items: center;
  text-align: center;
  border-right: 1px solid white;
  justify-content: center;

  background-color: #dddddd;
  margin: 0vw;
}

.fila p:first-child {
  text-align: left;
  width: 48%;
  padding-left: 2%;
  justify-content: left;
}

.fila p:last-child {
  text-align: center;
  width: 20%;
  cursor: pointer;
}

.fila p:last-child:hover {
  font-weight: 800;
}

.fila {
  display: flex;
  height: 2vw;
  align-items: center;
  margin-bottom: 0.3vw;
}

.reg_gu_cont {
  justify-content: center;
  margin-top: 2vw;
  margin-bottom: 5vw;
}

.atr-c {
  margin-right: 2.3vw;
}

.menu-atr {
  display: flex;
}

.menu-atr p {
  background-color: #242424;
  padding: 0.7vw;
  color: white;
  font-size: 0.9vw;
  margin: 0 0.2vw 1.2vw 0;
  cursor: pointer;
}

.menu-atr p.act {
  background-color: var(--color-1);
}

.item_gallery {
  width: fit-content;
  background-color: gainsboro;
  border-radius: 2vw;
  margin: 1vw 0;
}

.item_gallery .row {
  padding: 1vw;
}

.item_gallery img {
  width: 10vw;
}

.item_gallery .bsize img {
  width: 20vw;
}

@media(min-width: 1920px) {
  .opt-c {
    font-size: 0.58vw;
  }
}

/*FIN ESTILOS ADMINISTRADOR */
</style>
